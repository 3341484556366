import React, { useState } from "react";

const Share = (props) => {
  //FUNCS

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function (err) {
      console.error("Async: Could not copy text: ", err);
    });
  }

  const copyFunction = () => {
    copyTextToClipboard(uri);
  };
  //FUNCS

  //PROPS
  const { uri } = props;
  //PROPS

  return (
    <li className="m_dicas__social-item">
      <button onClick={copyFunction} aria-label="Share Page">
        <img src="/images/icons/link.png" alt="" />
      </button>
    </li>
  );
};

export default Share;
